import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)

/*if (Notification.permission !== 'granted') {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted');
        } else {
            console.log('Notification permission denied');
        }
    });
} else {
    console.log('Notification permission already granted');
}

if ('launchQueue' in navigator) {
    navigator.launchQueue.setConsumer((launchParams) => {
        if (launchParams.urlParams) {
            const urlParams = new URLSearchParams(launchParams.urlParams);
            const launchArg = urlParams.get('launch');
            console.log('PWA application launched via custom protocol:', launchArg);
        }
    });
}*/

const app = createApp(App)

app.use(router)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
