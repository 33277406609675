<template>
    <div>
        <div>
            <!--   header   -->
            <div class="header" :class="curFlex ? 'headerFlex' : ''">
                <div class="headerLogo">
                    <svg class="kOqhQd" aria-hidden="true" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0,0h40v40H0V0z"></path>
                        <g>
                            <path
                                d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                                fill="#EA4335"></path>
                            <path
                                d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                                fill="#FBBC04"></path>
                            <path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                                fill="#4285F4">
                            </path>
                            <path
                                d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                                fill="#34A853"></path>
                        </g>
                    </svg>
                    <span aria-hidden="true">google Play</span>
                </div>
                <div class="headerIcon">
                    <div class="newicon"><img src="../assets/r_headicon.png" fetchpriority="low" alt="icon1" /></div>
                    <!-- <div class="smIcon"><img src="../assets/icon1.webp" fetchpriority="low" alt="icon1" /></div>
                    <div class="smIcon"><img src="../assets/icon2.webp" fetchpriority="low" alt="icon2" /></div>
                    <div class="bigIcon"><img src="../assets/icon3.png" fetchpriority="low" alt="icon3" /></div> -->
                </div>
            </div>
            <!--  footer  -->
            <div class="footer">
                <!--   introduce   -->
                <div class="introduce">
                    <div class="introduce_item">
                        <!--     head   -->
                        <div class="introduce_head">
                            <div class="loadingImg">
                                <div class="isdown" v-if="isCurDown">
                                    <div class="sk-chase">
                                        <div class="sk-chase-dot"></div>
                                        <div class="sk-chase-dot"></div>
                                        <div class="sk-chase-dot"></div>
                                        <div class="sk-chase-dot"></div>
                                        <div class="sk-chase-dot"></div>
                                        <div class="sk-chase-dot"></div>
                                    </div>
                                </div>
                                <img src="../assets/unnamed.png" fetchpriority="low" alt="unnamed"
                                    :class="isCurDown ? 'curDown' : ''" />
                            </div>
                            <div class="loadingtext">
                                <h1>Motilal Oswal MF & PMS</h1>
                                <div class="mt2">
                                    <p class="p1">
                                        <span v-if="isCurDown">{{ curDownSchedule }}%</span><label v-else>otilal
                                            Oswal Asset
                                            Management Company Ltd.</label>
                                    </p>
                                    <p class="p2 mt2"><font-awesome-icon :icon="['fasl', 'location-dot']"
                                            style="margin-right: 3px" />Verified Play Protect</p>
                                </div>
                            </div>
                        </div>
                        <!--    foot   -->
                        <div class="introduce_foot">
                            <div class="introduce_foot_item">
                                <p class="p1">4.6</p>
                                <p class="p2">462 reviews</p>
                            </div>
                            <div class="introduce_foot_item">
                                <p class="p1">50K+</p>
                                <p class="p2">Downloads</p>
                            </div>
                            <div class="introduce_foot_item">
                                <p class="p1"><img src="../assets/introduce_icon1.webp" fetchpriority="low"
                                        alt="introduce_icon1" /></p>
                                <p class="p2">Rated for 3+<font-awesome-icon :icon="['fast', 'circle-info']"
                                        style="margin-left: 5px;font-size: .6rem;" /></p>
                            </div>
                        </div>
                        <!--     btn    -->
                        <div class="introduce_btn">
                            <div class="down" :class="isCurDown ? 'curDown' : ''" id="openCreate"
                                @click="isCurDown ? '' : installClick()" v-if="!isCurDownOver">
                                <p class="p1"><font-awesome-icon :icon="['fas', 'bolt']"
                                        style="margin-right: 8px" />Installing</p>
                                <p class="p2">Download within {{ curDownSum }} s</p>
                            </div>
                            <div class="play" @click="playClick" v-else>play</div>
                        </div>
                        <!--     share    -->
                        <div class="share">
                            <div class="left"><svg width="24" height="24" viewBox="0 0 24 24"
                                    style="color: #01875f;fill: #01875f;stop-color: #01875f;">
                                    <path
                                        d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z">
                                    </path>
                                </svg><span>Share</span></div>
                            <div class="right">
                                <svg width="24" height="24" viewBox="0 0 24 24"
                                    style="color: rgb(154,160,166);fill: rgb(154,160,166);stop-color: rgb(154,160,166);">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z">
                                    </path>
                                </svg>
                                <span>Add to wishlist</span>
                            </div>
                        </div>
                        <!--   devices   -->
                        <div class="devices">
                            <img src="../assets/devices.png" fetchpriority="low" alt="devices"
                                style="margin-right:3px; width:25px; vertical-align: middle;" />
                            <span>You don't have any devices</span>
                        </div>
                    </div>
                </div>
                <!--    introduce_img    -->
                <div class="introduce_img">
                    <swiper :slides-per-view="3" :space-between="12" @click="onSwiper" @slideChange="onSlideChange">
                        <swiper-slide><img src="../assets/introduce_img1.webp" fetchpriority="low"
                                alt="introduce_img1" /></swiper-slide>
                        <swiper-slide><img src="../assets/introduce_img2.webp" fetchpriority="low"
                                alt="introduce_img2" /></swiper-slide>
                        <swiper-slide><img src="../assets/introduce_img3.webp" fetchpriority="low"
                                alt="introduce_img3" /></swiper-slide>
                        <swiper-slide><img src="../assets/introduce_img4.webp" fetchpriority="low"
                                alt="introduce_img4" /></swiper-slide>
                        <swiper-slide><img src="../assets/introduce_img5.webp" fetchpriority="low"
                                alt="introduce_img5" /></swiper-slide>
                    </swiper>
                </div>
                <!--   introduce_text  -->
                <div class="introduce_text">
                    <div class="introduce_text_tit">
                        <div class="tit">About this app</div>
                        <div class="right"><img id="open" src="../assets/right.webp" @click="introduceOpen" /></div>
                    </div>
                    <div class="introduce_text_con">
                        <p>Motilal Oswal PMS is a tailor-made investment app for high net worth users.</p>
                        <p>With our stringent security measures and adherence to industry best practices, we ensure your
                            funds are always protected. With a legacy of 36 years, we have been dedicated to
                            safeguarding investors' funds and trust, earning their confidence through our unwavering
                            commitment to excellence. Additionally, our institution is SEBI approved [INP000004409],
                            further emphasizing our strong commitment to regulatory compliance and investor protection.
                        </p>
                    </div>
                    <div class="introduce_text_time">
                        <p class="p1">Updated on</p>
                        <p class="p2">19 Jun 2024</p>
                    </div>
                    <div class="introduce_text_tag">
                        <div>Finance</div>
                    </div>
                    <div class="introduce_text_tit">
                        <div class="tit">Data safety</div>
                        <div class="right"><img src="../assets/right.webp" fetchpriority="low" alt="right" /></div>
                    </div>
                    <div class="introduce_text_con">
                        Safety starts with understanding how developers collect and share your data. Data privacy and
                        security practices may vary based on your use, region, and age. The developer provided this
                        information and may update it over time.
                    </div>
                    <div class="introduce_text_safety">
                        <div>
                            <div class="safety_left"><img src="../assets/safety_img1.webp" fetchpriority="low"
                                    alt="safety_img1" /></div>
                            <div class="safety_right">
                                <p class="p1">No data shared with third parties</p>
                                <p class="p2"><u style="margin-right: 2px;">Learn more</u>about how developers declare
                                    sharing</p>
                            </div>
                        </div>
                        <div>
                            <div class="safety_left"><img src="../assets/safety_img2.webp" fetchpriority="low"
                                    alt="safety_img2" /></div>
                            <div class="safety_right">
                                <p class="p1">This app may collect these data types</p>
                                <p class="p2">App activity, App info and performance and Device or other IDs</p>
                            </div>
                        </div>
                        <div>
                            <div class="safety_left"><img src="../assets/safety_img3.webp" fetchpriority="low"
                                    alt="safety_img3" /></div>
                            <div class="safety_right">
                                <p class="p1">Data is encrypted in transit</p>
                            </div>
                        </div>
                        <div>
                            <div class="safety_left"><img src="../assets/safety_img4.webp" fetchpriority="low"
                                    alt="safety_img4" /></div>
                            <div class="safety_right">
                                <p class="p1">You can request that data be deleted</p>
                            </div>
                        </div>
                        <div class="details">
                            See details
                        </div>
                    </div>
                    <div class="introduce_text_tit">
                        <div class="tit">Ratings and reviews</div>
                        <div class="right"><img src="../assets/right.webp" /></div>
                    </div>
                    <div class="introduce_text_evaluate">
                        Ratings and reviews are verified<font-awesome-icon :icon="['fast', 'circle-info']"
                            style="margin-left: 5px;font-size: .7rem;" />
                    </div>
                </div>
                <!--             evaluate          -->
                <div class="evaluate">
                    <div class="evaluate_equipment">
                        <div class="evaluate_equipment_item">
                            <img src="../assets/iphone.webp" />
                            phone
                        </div>
                        <div class="evaluate_equipment_item">
                            <img src="../assets/ipad.webp" />
                            Tablet
                        </div>
                    </div>
                    <div class="evaluate_score">
                        <div class="evaluate_score_left">
                            <p class="p1">4.8</p>
                            <p class="p2"><img src="../assets/w.webp" /><img src="../assets/w.webp" /><img
                                    src="../assets/w.webp" /><img src="../assets/w.webp" /><img
                                    src="../assets/w_b.webp" />
                            </p>
                            <p class="p3">821K reviews</p>
                        </div>
                        <div class="evaluate_score_right">
                            <div>
                                <span>5</span>
                                <div class="rBall">
                                    <div class="rBall_green" style="width: 90%;"></div>
                                </div>
                            </div>
                            <div>
                                <span>4</span>
                                <div class="rBall"></div>
                            </div>
                            <div>
                                <span>3</span>
                                <div class="rBall">
                                    <div class="rBall_green" style="width: 6%;"></div>
                                </div>
                            </div>
                            <div>
                                <span>2</span>
                                <div class="rBall">
                                    <div class="rBall_green" style="width: 4%;"></div>
                                </div>
                            </div>
                            <div>
                                <span>1</span>
                                <div class="rBall"></div>
                            </div>
                        </div>
                    </div>
                    <div class="evaluate_figure">
                        <div class="evaluate_figure_item">
                            <div class="evaluate_figure_item_head">
                                <div class="evaluate_figure_item_head_left">
                                    <img src="../assets/score_icon1.jpg" />
                                    ANTI
                                </div>
                                <div class="evaluate_figure_item_head_right">
                                    <img src="../assets/more.webp" />
                                </div>
                            </div>
                            <div class="evaluate_figure_item_time">
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <span>24 April 2024</span>
                            </div>
                            <div class="evaluate_figure_item_con">
                                You can't get all your investments in one shot.. why did you change? Horrible user
                                experience. I don't even have an option not to update. Could you revoke the update?
                                Update: (as on 17Jan2024) The bug is fixed and I can see the break up of my investments.
                                However, 1. The break of amount invested under each head is not visible. Only
                                consolidated investments is shown. 2. I need a pie chart presentation.
                            </div>
                            <div class="evaluate_figure_item_works">
                                309 people found this review helpful
                            </div>
                            <div class="evaluate_figure_item_help">
                                <label>Did you find this helpful?</label>
                                <span>Yes</span><span>No</span>
                            </div>
                            <div class="evaluate_figure_item_curHelp">
                                <div class="evaluate_figure_item_curHelp_header">
                                    <div class="name">Motilal Oswal Asset Management Company Ltd.</div>
                                    <div class="time">24 April 2024</div>
                                </div>
                                <div class="evaluate_figure_item_curHelp_foot">
                                    Hello Andaja We regret the inconvenience; our team is constantly working on
                                    supporting many more
                                    use cases and devices. We will surely get back to you once we have an update.
                                    Team Motilal Oswal
                                </div>
                            </div>
                        </div>
                        <div class="evaluate_figure_item">
                            <div class="evaluate_figure_item_head">
                                <div class="evaluate_figure_item_head_left">
                                    <img src="../assets/score_icon2.jpg" />
                                    Lek Hang Derrence Tan
                                </div>
                                <div class="evaluate_figure_item_head_right">
                                    <img src="../assets/more.webp" />
                                </div>
                            </div>
                            <div class="evaluate_figure_item_time">
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w_b.webp" />
                                <span>12 October 2023</span>
                            </div>
                            <div class="evaluate_figure_item_con">
                                App has one major issue..It logs out the user permanently very frequently. Then starts
                                the process of otps and enabling the fingerprint all over again. Also it does not work
                                24/7..Evening and weekends..forget about it..Having used other apps..like ICICI n Bajaj
                                allianz..I see this as a major bug.. that needs to be fixed. Please look into it to
                                improve user experience. Thanks in advance.
                            </div>
                            <div class="evaluate_figure_item_works">
                                209 people found this review helpful
                            </div>
                            <div class="evaluate_figure_item_help">
                                <label>Did you find this helpful?</label>
                                <span>Yes</span><span>No</span>
                            </div>
                        </div>
                        <div class="evaluate_figure_item">
                            <div class="evaluate_figure_item_head">
                                <div class="evaluate_figure_item_head_left">
                                    <img src="../assets/score_icon3.png" />
                                    Kye Johnson
                                </div>
                                <div class="evaluate_figure_item_head_right">
                                    <img src="../assets/more.webp" />
                                </div>
                            </div>
                            <div class="evaluate_figure_item_time">
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <img src="../assets/w.webp" />
                                <span>27 August 2023</span>
                            </div>
                            <div class="evaluate_figure_item_con">
                                It's really disappointing Motilal Oswal mutual fund app is not working..and they are not
                                making changes also...not recommended to invest bcz we can't track of our investment...
                            </div>
                            <div class="evaluate_figure_item_works">
                                62 people found this review helpful
                            </div>
                            <div class="evaluate_figure_item_help">
                                <label>Did you find this helpful?</label>
                                <span>Yes</span><span>No</span>
                            </div>
                            <div class="evaluate_figure_item_curHelp">
                                <div class="evaluate_figure_item_curHelp_header">
                                    <div class="name">Motilal Oswal Asset Management Company Ltd.</div>
                                    <div class="time">28 August 2023</div>
                                </div>
                                <div class="evaluate_figure_item_curHelp_foot">
                                    Dear Ms Yadav, we sincerely regret the inconvenience caused. But we are always here
                                    to help. Could you kindly message us at amc@motilaloswal.com? We'd really like to
                                    connect, understand the issues faced and do our best to resolve them. Our
                                    development team is very active fixing bugs and improving performance, and your
                                    inputs will help immensely.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--             function          -->
                <div class="function">
                    <div class="function_tit">
                        What's new
                    </div>
                    <div class="function_con">
                        <p>A new version is online!</p>
                        <p>Billions of funds continue to flow, enjoy a smoother and bug-free gaming experience!</p>
                        <p>Time is money, dear tycoons! Update to the latest version quickly and bravely climb new
                            wealth peaks!</p>
                    </div>
                </div>


                <!--           support             -->
                <div class="support">
                    <div class="support_tit">
                        App support
                    </div>
                    <div class="support_con">
                        <div class="support_con_item">
                            <div class="support_con_item_left"><img src="../assets/support_icon1.webp" /></div>
                            <div class="support_con_item_right">
                                <p class="p1">Website</p>
                            </div>
                        </div>
                        <div class="support_con_item">
                            <div class="support_con_item_left"><img src="../assets/support_icon5.png" /></div>
                            <div class="support_con_item_right">
                                <p class="p1">Phone number</p>
                                <p class="p2">+918108622222</p>
                            </div>
                        </div>
                        <div class="support_con_item">
                            <div class="support_con_item_left"><img src="../assets/support_icon2.webp" /></div>
                            <div class="support_con_item_right">
                                <p class="p1">Support email</p>
                                <p class="p2">amc@motilaloswal.com</p>
                            </div>
                        </div>
                        <div class="support_con_item">
                            <div class="support_con_item_left"><img src="../assets/support_icon3.webp" /></div>
                            <div class="support_con_item_right">
                                <p class="p1">Address</p>
                                <p class="p2">10th Floor, Motilal Oswal Tower, Rahimtullah Sayani Road,Opposite Parel ST
                                    Bus Depot, Prabhadevi,Mumbai 400025</p>
                            </div>
                        </div>
                        <div class="support_con_item">
                            <div class="support_con_item_left"><img src="../assets/support_icon4.webp" /></div>
                            <div class="support_con_item_right">
                                <p class="p1">Privacy Policy</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!--            foot       -->
                <div class="foot">
                    <div class="foot_item">Terms of Service</div>
                    <div class="foot_item">Privacy</div>
                    <div class="foot_item">About Google Play</div>
                    <div class="foot_item">Developers</div>
                    <div class="foot_item">Google Store</div>
                    <div class="foot_item">All prices include GST.</div>
                    <div class="foot_item"><img src="../assets/india.png" />India (English (United Kingdom))</div>
                </div>

                <!--            comfig             -->
                <!-- <div class="comfigBk" v-if="configShow">
                    <div class="sk-chase">
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                    </div>
                </div>   -->

                <!--  pageFixed   -->
                <div class="pageFixed" v-if="installShow">
                    <div class="pageFixedCon">
                        <div class="pageFixedCon_head"><img src="../assets/r_headicon.png" /></div>
                        <div class="pageFixedCon_Text">
                            We are collecting <span>objective</span> reviews for this apps
                        </div>
                        <div class="pageFixedCon_Tx">
                            <div class="pageFixedCon_Tx_Left">
                                <img src="../assets/unnamed.png" />
                            </div>
                            <div class="pageFixedCon_Tx_Right">
                                <p class="p1">Demo PMS<img src="../assets/ic_tag.png" /></p>
                                <p class="p2">PMS BEST PTE. LTD.</p>
                                <p class="p3"><img src="../assets/ic_dun.png" />Verified by Play Protect</p>
                            </div>
                        </div>
                        <div class="pageFixedCon_foot">
                            <div class="pageFixedCon_foot_Left">
                                <img src="../assets/google_tck.png" />
                            </div>
                            <div class="pageFixedCon_foot_Right">
                                If you can <span>download the app</span> and leave an <span>objective</span> review and
                                rating，You will have the chance to receive a <span>$20</span> Google Play gift card.
                            </div>
                        </div>
                        <div class="pageFixedCon_btn" @click="installSumClick">Install</div>
                    </div>
                </div>

                <!--            fexid              -->
                <div class="fexid" v-if="isShow">
                    <div class="flex">
                        <div class="fexid_con">
                            <div class="fexid_head">
                                <div class="fexid_head_left">
                                    <img src="../assets/unnamed_pic.png" />
                                    <div class="fexid_text">
                                        <p class="p1">Motilal Oswal MF & PMS</p>
                                        <p class="p2">About this app</p>
                                    </div>
                                </div>
                                <div class="fexid_head_right">
                                    <img id="close" @click="introduceClose" src="../assets/close.webp" />
                                </div>
                            </div>
                            <div class="fexid_foot">
                                <div class="fexid_foot_con">
                                    <p>This investment app is really very good! The interface is intuitive, and the
                                        operation is simple. I can easily find the functions I need.
                                    </p>
                                    <p>As a high net worth user, this app completely meets my needs. High security,
                                        comprehensive functions, and very trustworthy.</p>
                                    <p>The experience after the update is excellent, with richer features and smoother
                                        operation. Highly recommended!
                                    </p>
                                    <p>Using this app for investment management is really efficient. It's not only
                                        convenient but also keeps me updated with market trends at any time.
                                    </p>
                                    <p>The Motilal Oswal PMS app has greatly enhanced my investment experience. Both its
                                        security and user experience are outstanding.</p>
                                    <p>The customer service of this app is also very good. Every time I encounter a
                                        problem, I get help and solutions promptly."</p>
                                    <p>I like the personalized investment advice feature of this app. It provides
                                        customized investment strategies based on my needs.</p>
                                    <p>The interface design is simple and elegant, and the data display is clear and
                                        easy to understand, perfect for investment beginners like me.</p>
                                    <p>The performance of the investment app is very stable. It has never crashed or
                                        lagged, making it very pleasant to use.</p>
                                    <p>I really appreciate the real-time data update feature of this app, which allows
                                        me to grasp market trends immediately.</p>
                                    <p>Through this app, I can easily manage multiple investment accounts without
                                        switching between different platforms.</p>
                                    <p>The security is very high. Every transaction has double verification, making it
                                        very reassuring to use.</p>
                                    <p>The research reports and market analysis functions of this app are very powerful
                                        and greatly help my investment decisions.</p>
                                    <p>Motilal Oswal PMS's app has helped me easily achieve wealth growth. I am really
                                        satisfied.</p>
                                    <p>After using this app, my investment efficiency has greatly improved, saving me
                                        time and effort</p>
                                </div>
                                <div class="fexid_foot_condition">
                                    <div class="fexid_foot_condition_item">
                                        <div class="fexid_foot_condition_item_left">
                                            <p class="p1">Version</p>
                                            <p class="p2">2.4</p>
                                        </div>
                                        <div class="fexid_foot_condition_item_right">
                                            <p class="p1">Updated on</p>
                                            <p class="p2">19 Jun 2024</p>
                                        </div>
                                    </div>
                                    <div class="fexid_foot_condition_item">
                                        <div class="fexid_foot_condition_item_left">
                                            <p class="p1">Requires Android</p>
                                            <p class="p2">7.1 and up</p>
                                        </div>
                                        <div class="fexid_foot_condition_item_right">
                                            <p class="p1">Downloads</p>
                                            <p class="p2">50,000+ downloads</p>
                                        </div>
                                    </div>
                                    <div class="fexid_foot_condition_item">
                                        <div class="fexid_foot_condition_item_left">
                                            <p class="p1">Content rating</p>
                                            <p class="p2">Rated for 3+<span>Learn more</span></p>
                                        </div>
                                        <div class="fexid_foot_condition_item_right">
                                            <p class="p1">Permission</p>
                                            <p class="p2"><span>View details</span></p>
                                        </div>
                                    </div>
                                    <div class="fexid_foot_condition_item">
                                        <div class="fexid_foot_condition_item_left">
                                            <p class="p1">Released on</p>
                                            <p class="p2">17 Sept 2023</p>
                                        </div>
                                        <div class="fexid_foot_condition_item_right">
                                            <p class="p1">Offered by</p>
                                            <p class="p2">Motilal Oswal Asset Management Company Ltd.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

let isCurDown = ref(false);

let isCurDownOver = ref(false);

let curDownSum = ref(10);

let curDownSchedule = ref(0);

let isShow = ref(false);

//let isDwonApp = false;

//let configShow = ref(false);

let jumpUrl = "https://jump.play-goole.com/";

let installShow = ref(false);

let curFlex = ref(false);

let routerAdress = ref("");

import { ref, onMounted, onUnmounted } from "vue";

import { useRouter } from "vue-router";


onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    routerAdress.value = useRouter();
    if (source == "pwa") {
        routerAdress.value.push("/chi");
    } else {
        const browser = isGoogleChrome();
        const mobile = isMobile();
        if (isSamsungBrowser()) {
            installShow.value = true;
        }
        else if (!browser && mobile) {
            installShow.value = true;
            let currentUrl = window.location.href;
            location.href = `intent://${currentUrl.replace(/(https|http):\/\//, "")}#Intent;scheme=https;action=android.intent.action.VIEW;component=com.android.chrome;package=com.android.chrome;end`;
        } else {
            installShow.value = true;
        }
    }
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

const handleScroll = () => {
    if (window.scrollY > 50) {
        curFlex.value = true;
    } else {
        curFlex.value = false;
    }
};

const isSamsungBrowser = () => {
    var userAgent = window.navigator.userAgent;
    return userAgent.includes('SamsungBrowser');
}

const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const isGoogleChrome = () => {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isSamSung = winNav.userAgent.indexOf("SamsungBrowser") > -1;
    var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        !isOpera &&
        !isIEedge &&
        !isSamSung
    ) {
        return true;
    } else {
        return false;
    }
}

//pwa click  
const installClick = () => {
    const browser = isGoogleChrome();
    const mobile = isMobile();
    if (!browser && mobile) {
        let currentUrl = window.location.href;
        location.href = `intent://${currentUrl.replace(/(https|http):\/\//, "")}#Intent;scheme=https;action=android.intent.action.VIEW;component=com.android.chrome;package=com.android.chrome;end`;
    }
    else {
        try {
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    //isDwonApp = true;
                    Downfun();
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                window.deferredPrompt = null;
            });
        } catch {
            //isDwonApp = false;
            isCurDownOver.value = true;
        }
    }
};

const installSumClick = () => {
    if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
        document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
        document.documentElement.msRequestFullscreen();
    }
    const browser = isGoogleChrome();
    const mobile = isMobile();
    if (!browser && mobile) {
        let currentUrl = window.location.href;
        location.href = `intent://${currentUrl.replace(/(https|http):\/\//, "")}#Intent;scheme=https;action=android.intent.action.VIEW;component=com.android.chrome;package=com.android.chrome;end`;
    }
    installShow.value = false;
}

const playClick = async () => {
    window.location.href = jumpUrl + '?host=' + getDomain(window.location.href);
}

const getDomain = (url) => {
    var match = url.match(/:\/\/(.[^/]+)/i);
    return match ? match[1] : '';
}


/*const isBrowserRedirect = () => {
    let isPC = false;
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
        isPC = true;
    }
    return isPC;
}

const postMessagePwa = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.controller.postMessage({ action: 'openMessagePWA', title: 'Motilal Oswal MF & PMS', content: 'Welcome to the Motilal Oswal MF & PMS!' });
    }
}*/

const Downfun = () => {
    isCurDown.value = true;
    DownScheduleFun();
    let timer = setInterval(() => {
        if (curDownSum.value == 0) {
            isCurDown.value = false;
            isCurDownOver.value = true;
            clearInterval(timer);
            curDownSum.value = 10;
            curDownSchedule.value = 0;
        }
        else {
            DownScheduleFun();
            curDownSum.value = curDownSum.value - 1;
        }
    }, 1000)
}

const DownScheduleFun = () => {
    let o = 1;
    let timerSchedule = setInterval(() => {
        if (o == 12 || curDownSchedule.value == 100) {
            clearInterval(timerSchedule)
        } else {
            o++
            curDownSchedule.value++
        }
    }, 80)
}


//Introduction open
const introduceOpen = () => {
    isShow.value = true;
}

//Introduction close
const introduceClose = () => {
    isShow.value = false;
}

</script>

<style scoped>
.scroll-container {
    overflow-y: auto;
    height: 100vh;
}

/***************** swiper **********************/
.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.headerFlex {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999999999;
}

.header .headerLogo {
    display: flex;
    align-items: center;
    margin: 0 16px;
    height: 56px;
    font-family: "GoogleSans", sans-serif;
    line-height: 40px;
    font-size: 18px;
}

.header .headerLogo svg {
    width: 40px;
    height: 40px;
    margin-right: 8px;
}

.header .headerLogo span {
    color: #5F6368;
    font-weight: bold;
}

.header .headerIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 0 auto;
}

.header .headerIcon .newicon {
    padding: 5px;
    width: 120px;
}

.header .headerIcon .newicon img {
    width: 100%;
}

.header .headerIcon .smIcon {
    padding: 12px;
}

.header .headerIcon .smIcon img {
    width: 24px;
    height: 24px;
}

.header .headerIcon .bigIcon {
    padding: 8px;
}

.header .headerIcon .bigIcon img {
    height: 32px;
    width: 32px;
    border-radius: 32px;
}

/****                         footer            *********************/
.footer {
    /*height: calc(100vh - 56px);
    overflow-y: auto;*/
}


/*******************               introduce            **********************/
.introduce {
    padding: 0 24px;
    margin-bottom: 30px;
}

.introduce .introduce_item {
    margin-top: 30px;
}

.introduce .introduce_item .introduce_head {
    align-items: flex-start;
    display: flex;
}

.introduce .introduce_item .introduce_head .loadingImg {
    width: 72px;
    margin: 3px 12px 0 0;
    position: relative;
}

.introduce .introduce_item .introduce_head .loadingImg .isdown {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 100%;
    width: 100%;
}

.introduce .introduce_item .introduce_head .loadingtext {
    width: calc(100% - 82px);
}

.introduce .introduce_item .introduce_head img {
    width: 72px;
    border-radius: 20%;
}

.introduce .introduce_item .introduce_head img.curDown {
    animation: scale 0.5s;
    animation-fill-mode: forwards;
}

@keyframes scale {
    100% {
        transform: scale(0.5);
    }
}

.introduce .introduce_item .introduce_head h1 {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 2rem;
    font-size: 1.3rem;
    letter-spacing: 0;
    font-weight: bold;
    align-items: flex-end;
    display: flex;
    overflow-wrap: anywhere;
    width: 100%;
    word-break: keep-all;
}

.introduce .introduce_item .introduce_head .mt2 {
    margin-top: 2px;
}

.introduce .introduce_item .introduce_head .p1 {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .00625em;
    font-weight: 500;
    color: #01875f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.introduce .introduce_item .introduce_head .p1 span {
    font-size: .75rem;
    letter-spacing: .025em;
    color: rgb(95, 99, 104);
}

.introduce .introduce_item .introduce_head .p2 {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
}


.introduce .introduce_item .introduce_foot {
    height: 64px;
    margin: 8px 0 10px 0px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.introduce .introduce_item .introduce_foot .introduce_foot_item {
    min-width: 96px;
    padding: 0 16px;
    position: relative;
    text-align: center;
}

.introduce .introduce_item .introduce_foot .introduce_foot_item:not(:first-child)::before {
    background-color: rgb(232, 234, 237);
    content: "";
    display: block;
    height: 24px;
    left: 0;
    position: absolute;
    top: calc(50% - 12px);
    width: 1px;
}

.introduce .introduce_item .introduce_foot .introduce_foot_item .p1 {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.25rem;
    font-size: .875rem;
    letter-spacing: .0178571429em;
    font-weight: 500;
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    white-space: nowrap;
}


.introduce .introduce_item .introduce_foot .introduce_foot_item .p2 {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
    align-items: center;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.introduce .introduce_item .introduce_btn {
    display: flex;
    justify-content: space-between;
}

.introduce .introduce_item .introduce_btn div {
    width: calc(100%);
    text-align: center;
    background-color: #01875f;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: .875rem;
    letter-spacing: .0178571429em;
    font-weight: 500;
    padding: 10px 0px;
    border-radius: 8px;
}

.introduce .introduce_item .introduce_btn .play {
    color: #fff;
    font-size: 1rem;
    padding: 12px 0px;
}

.introduce .introduce_item .introduce_btn div.curDown {
    opacity: 0.5;
}

.introduce .introduce_item .introduce_btn div .p1 {
    color: #daf700;
    font-size: 1rem;
}

.introduce .introduce_item .introduce_btn div .p2 {
    color: #fff;
    font-size: .875rem;
    margin-top: 8px;
}

.introduce .introduce_item .share {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.introduce .introduce_item .share div {
    display: flex;
    align-items: center;
    padding: 10px;
}

.introduce .introduce_item .share div span {
    margin-left: 8px;
}

.introduce .introduce_item .share div.left {
    font-size: .875rem;
    color: #01875f;
    font-weight: 500;
}

.introduce .introduce_item .share div.right {
    font-size: .875rem;
    color: rgb(154, 160, 166);
    font-weight: 500;
}

.introduce .introduce_item .devices {
    margin-top: 10px;
    font-size: .8rem;
    color: rgb(95, 99, 104);
    margin-top: 20px;
    margin-bottom: 42px;
}

.introduce .introduce_item .devices span {
    margin-left: 10px;
}


/*******************               introduce_img            **********************/
.introduce_img {
    padding: 0 24px;
    margin-bottom: 30px;
}

.introduce_img img {
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    border-radius: 8px;
}


/*******************               introduce_text            **********************/
.introduce_text {
    padding: 0 24px;
    margin-bottom: 30px;
}

.introduce_text .introduce_text_tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.introduce_text .introduce_text_tit .tit {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1.125rem;
    letter-spacing: 0;
    font-weight: 400;
    font-weight: 500;
}

.introduce_text .introduce_text_tit .right img {
    width: 20px;
    height: 20px;
}

.introduce_text .introduce_text_con {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    max-height: 7.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(95, 99, 104);
}

.introduce_text .introduce_text_con p {
    margin-bottom: 20px;
}

.introduce_text .introduce_text_time {
    margin-top: 24px;
}

.introduce_text .introduce_text_time .p1 {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.25rem;
    font-size: .875rem;
    letter-spacing: .0178571429em;
    font-weight: 500;
}


.introduce_text .introduce_text_time .p2 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    margin-top: 8px;
}


.introduce_text .introduce_text_tag {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.introduce_text .introduce_text_tag div {
    padding: 0 15px 0 15px;
    margin-right: 12px;
    margin-bottom: 16px;
    height: 32px;
    line-height: 32px;
    color: rgb(95, 99, 104);
    border-radius: 16px;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: .875rem;
    letter-spacing: .0107142857em;
    font-weight: 500;
    border: 1px solid #E3E5E8;
}

.introduce_text .introduce_text_safety {
    margin-top: 25px;
    border-radius: 8px;
    border: 1px solid #DADCE0;
    margin-bottom: 20px;
    padding: 20px 20px 0;
}

.introduce_text .introduce_text_safety .details {
    padding: .0rem .2rem;
    color: #01875f;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .0107142857em;
}

.introduce_text .introduce_text_safety>div {
    display: flex;
    margin-bottom: 20px;
}

.introduce_text .introduce_text_safety>div .safety_left {
    margin-right: 20px;

}

.introduce_text .introduce_text_safety>div .safety_left img {
    width: 20px;
    height: 20px;
    margin-top: -2px;
}

.introduce_text .introduce_text_safety>div .safety_right .p1 {
    color: rgb(95, 99, 104);
    border-radius: 16px;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: .875rem;
    letter-spacing: .0107142857em;
    font-weight: 500;
}

.introduce_text .introduce_text_safety>div .safety_right .p2 {
    margin-top: 2px;
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
}

.introduce_text .introduce_text_evaluate {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
}

/***************                     evaluate                   ********************/
.evaluate {
    padding: 0 24px;
    margin-bottom: 30px;
}

.evaluate .evaluate_equipment {
    display: flex;
}

.evaluate .evaluate_equipment .evaluate_equipment_item {
    padding: 6px 16px;
    border: 1px solid #DADCE0;
    color: rgb(95, 99, 104);
    border-radius: 16px;
    margin-right: 12px;
    font-size: .875rem;
}

.evaluate .evaluate_equipment .evaluate_equipment_item img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.evaluate .evaluate_score {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
}

.evaluate .evaluate_score .evaluate_score_left .p1 {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans Display", Roboto, Arial, sans-serif;
    line-height: 4rem;
    font-size: 3.5rem;
    letter-spacing: 0;
    font-weight: 400;
}

.evaluate .evaluate_score .evaluate_score_left .p2 img {
    width: 16px;
    height: 16px;
}

.evaluate .evaluate_score .evaluate_score_left .p3 {
    margin-top: .5rem;
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
}

.evaluate .evaluate_score .evaluate_score_right {
    margin-left: 24px;
    width: calc(100% - 24px - 80px);
}

.evaluate .evaluate_score .evaluate_score_right>div {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.evaluate .evaluate_score .evaluate_score_right span {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
    padding-right: 16px;
}

.evaluate .evaluate_score .evaluate_score_right .rBall {
    width: calc(100% - 30px);
    background-color: rgb(232, 234, 237);
    border-radius: 9999px;
    height: 10px;
}

.evaluate .evaluate_score .evaluate_score_right .rBall .rBall_green {
    background-color: #01875f;
    border-radius: 9999px;
    height: 10px;
}


.evaluate .evaluate_figure {
    margin-top: 20px;
}

.evaluate .evaluate_figure .evaluate_figure_item {
    padding: 16px 0px;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_head .evaluate_figure_item_head_left {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    align-items: center;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_head .evaluate_figure_item_head_left img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    background-size: cover;
    margin-right: 10px;
    vertical-align: middle;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_head .evaluate_figure_item_head_right img {
    width: 24px;
    height: 24px;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_time {
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_time img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_time span {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    font-size: .75rem;
    font-weight: 400;
    letter-spacing: .025em;
    line-height: 1rem;
    margin-left: 1ch;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_con {
    margin-top: 8px;
    font-size: 12px;
    letter-spacing: .2px;
    line-height: 20px;
    color: rgb(95, 99, 104);
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_works {
    margin-top: 16px;
    font-family: Roboto, Arial, sans-serif;
    font-size: .75rem;
    font-weight: 400;
    letter-spacing: .025em;
    line-height: 1rem;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_help {
    align-items: center;
    display: flex;
    margin-top: 12px;
    font-family: Roboto, Arial, sans-serif;
    font-size: .75rem;
    font-weight: 400;
    letter-spacing: .025em;
    line-height: 1rem;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_help label {
    margin-right: 24px;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_help span {
    padding: 6px 20px;
    border: 1px solid #E0E2E5;
    border-radius: 9999px;
    margin-right: 10px;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_curHelp {
    margin: 24px 24px 0;
    padding: 12px 16px 16px;
    background-color: rgb(248, 249, 250);
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_curHelp .evaluate_figure_item_curHelp_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_curHelp .evaluate_figure_item_curHelp_header .name {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_curHelp .evaluate_figure_item_curHelp_header .time {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1rem;
    font-size: 11px;
    letter-spacing: .025em;
    font-weight: 400;
    margin-left: auto;
}

.evaluate .evaluate_figure .evaluate_figure_item .evaluate_figure_item_curHelp .evaluate_figure_item_curHelp_foot {
    margin-top: 12px;
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
}


/*************                       function                   *******************/
.function {
    padding: 0 24px;
    margin-bottom: 30px;
}

.function .function_tit {
    padding-bottom: 20px;
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1.125rem;
    letter-spacing: 0;
    font-weight: 400;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.function .function_con {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}


/************                     support                      *********************/
.support {
    padding: 0 24px;
    margin-bottom: 30px;
}

.support .support_tit {
    padding-bottom: 20px;
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1.125rem;
    letter-spacing: 0;
    font-weight: 400;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}


.support .support_con .support_con_item {
    display: flex;
    padding: 12px 0px;
}

.support .support_con .support_con_item .support_con_item_left {
    margin-right: 16px;
}

.support .support_con .support_con_item img {
    width: 18px;
    height: 18px;
}

.support .support_con .support_con_item .support_con_item_right .p1 {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.25rem;
    font-size: .875rem;
    letter-spacing: .0178571429em;
    font-weight: 500;
}

.support .support_con .support_con_item .support_con_item_right .p2 {
    color: rgb(95, 99, 104);
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    overflow-wrap: anywhere;
}



/*************                      foot                       ******************/
.foot {
    padding: 0 24px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
}

.foot .foot_item {
    font-family: "Google Sans", Helvetica, Arial, sans-serif;
    fill: rgb(95, 99, 104);
    stop-color: rgb(95, 99, 104);
    font-size: .75rem;
    line-height: 48px;
    margin-right: 24px;
    color: rgb(95, 99, 104);
}

.foot img {
    width: 24px;
    height: 18px;
    margin-right: 12px;
    vertical-align: middle;
}

.sk-chase {
    width: 60px;
    height: 60px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
    content: '';
    display: block;
    width: 15%;
    height: 15%;
    background-color: #01875f;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
    animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
    animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {

    80%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }

    100%,
    0% {
        transform: scale(1.0);
    }
}

/**************                      fexid                      *******************/
.fexid {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(32, 33, 36, .6);
    z-index: 9999;
    top: 0;
    left: 0;
}

.fexid .flex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fexid .fexid_con {
    height: calc(100vh - 120px);
    width: calc(100vw - 32px);
    background-color: #fff;
    border-radius: 8px;
    max-height: 720px;
    max-width: 720px;
}

.fexid .fexid_con .fexid_head {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fexid .fexid_con .fexid_head .fexid_head_left {
    display: flex;
}

.fexid .fexid_con .fexid_head .fexid_head_left img {
    width: 56px;
    border-radius: 8px;
    margin-right: 16px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, .09), 0 3px 5px rgba(0, 0, 0, .06), 0 1px 2px rgba(0, 0, 0, .3), 0 1px 3px rgba(0, 0, 0, .15);
}

.fexid .fexid_con .fexid_head .fexid_head_left .fexid_text .p1 {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    line-height: 1.75rem;
    font-size: 1.275rem;
    letter-spacing: 0;
    font-weight: 400;
    font-weight: 500;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
}

.fexid .fexid_con .fexid_head .fexid_head_left .fexid_text .p2 {
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0178571429em;
    color: rgb(95, 99, 104);
    line-height: 1.75rem;
}

.fexid .fexid_con .fexid_head .fexid_head_right img {
    width: 24px;
    height: 24px;
}

.fexid .fexid_con .fexid_foot {
    padding: 0px 32px;
    height: calc(100% - 104px);
    overflow-y: auto;
}

.fexid .fexid_con .fexid_foot .fexid_foot_con {
    border-bottom: 1px solid #E8EAED;
    margin-bottom: 24px;
    padding-bottom: 20px;
    font-size: 0.875rem;
    color: rgb(95, 99, 104);
}

.fexid .fexid_con .fexid_foot .fexid_foot_con p {
    margin-bottom: 10px;
    line-height: 25px;
}

.fexid .fexid_con .fexid_foot .fexid_foot_condition {
    padding-bottom: 30px;
}

.fexid .fexid_con .fexid_foot .fexid_foot_condition .fexid_foot_condition_item {
    display: flex;
    justify-content: space-between;
}

.fexid .fexid_con .fexid_foot .fexid_foot_condition .fexid_foot_condition_item .fexid_foot_condition_item_left,
.fexid .fexid_con .fexid_foot .fexid_foot_condition .fexid_foot_condition_item .fexid_foot_condition_item_right {
    width: 49%;
}

.fexid .fexid_con .fexid_foot .fexid_foot_condition .p1 {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 0.875rem;
    color: rgb(32, 33, 36);
}

.fexid .fexid_con .fexid_foot .fexid_foot_condition .p2 {
    line-height: 20px;
    font-size: 0.72rem;
    color: rgb(95, 99, 104);
}

.fexid .fexid_con .fexid_foot .fexid_foot_condition .p2 span {
    color: #01875f;
}

/**********         pageFixed        */
.pageFixed {
    background: rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pageFixed .pageFixedCon {
    width: calc(100% - 60px);
    background: #fff;
    border-radius: 18px;
    padding: 25px 20px;
}

.pageFixed .pageFixedCon .pageFixedCon_head {
    text-align: center;
}

.pageFixed .pageFixedCon .pageFixedCon_head img {
    width: 40%;
    max-width: 150px;
}

.pageFixed .pageFixedCon .pageFixedCon_Text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    overflow-wrap: anywhere;
    text-align: center;
    word-break: keep-all;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .23);
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 100%;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Left {
    margin-right: 20px;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Left img {
    width: 70px;
    height: 70px;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Right .p1 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Right .p1 img {
    margin-left: 3px;
    display: inline-block;
    height: 20px;
    vertical-align: baseline;
    width: 20px;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Right .p2 {
    color: #028760;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Right .p3 {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: 14px;
}

.pageFixed .pageFixedCon .pageFixedCon_Tx .pageFixedCon_Tx_Right .p3 img {
    width: 10px;
    margin-right: 4px;
}

.pageFixed .pageFixedCon .pageFixedCon_foot {
    align-items: center;
    display: flex;
}

.pageFixed .pageFixedCon .pageFixedCon_foot .pageFixedCon_foot_Left {
    margin-right: 12px;
}

.pageFixed .pageFixedCon .pageFixedCon_foot .pageFixedCon_foot_Left img {
    width: 70px;
}

.pageFixed .pageFixedCon .pageFixedCon_foot .pageFixedCon_foot_Right {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    margin: 10px 0;
}

.pageFixed .pageFixedCon .pageFixedCon_foot .pageFixedCon_foot_Right span {
    color: #028760;
    font-weight: 500;
}

.pageFixed .pageFixedCon .pageFixedCon_btn {
    align-items: center;
    background-color: #028760;
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 35px;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}
</style>