<template>
    <div>
        <iframe src="https://master.motilaloswalpms.online/" style="width: 100%; height: 100vh; border: 0; "></iframe>
    </div>
</template>


<script>
export default {
    name: 'chi'
}
</script>