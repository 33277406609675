import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import chi from '../components/chi.vue'
import PwaPage from '../components/PwaPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/chi',
      name: 'chi',
      component: chi
    },
    {
      path: '/open-app',
      name: 'open-app',
      component: PwaPage
    },
  ]
})

export default router
