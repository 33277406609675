<template>
    <div></div>
</template>

<script setup>

import { onMounted } from "vue";

import { useRouter } from "vue-router";

onMounted(() => {

    let routerAdress = useRouter();

    routerAdress.push("/chi");

})

</script>